<template>
  <v5-template :url="url" />
</template>

<script>

import auditController from '@/auditController'

export default {
  activated () {
    this.auditController.stopAudit()
  },
  components: {
    V5Template: () => import('./v5-template.vue')
  },
  computed: {
    url () {
      return `${new URL(document.location).origin}/v5/index.html#/about`
    }
  },
  data () {
    return {
      auditController: auditController
    }
  }
}
</script>
